<template>
  <VueFinalModal
    v-model="showModal"
    :esc-to-close="true"
    @closed="closed"
    class="modal-container"
    content-class="modal-content3"
  >
    <button
      v-if="!isPdf"
      class="p-1 btn btn-link text-dark fixed-plugin-close-button"
      style="
        background-color: #333333;
        border-radius: 16px;
        border: solid 3px #dcdcdc;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 5px 0 10px 0;
        align-self: self-end;
        padding: 6px 10px !important;
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 10;
      "
      @click="closed"
    >
      <i class="fa fa-close" style="color: #dcdcdc"></i>
    </button>
    <button
      v-if="isPdf"
      class="p-1 btn btn-link text-dark fixed-plugin-close-button"
      style="
        background-color: #333333;
        border-radius: 16px;
        border: solid 3px #dcdcdc;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 5px 0 10px 0;
        align-self: self-end;
        padding: 6px 10px !important;
        position: absolute;
        bottom: 12px;
        right: 12px;
      "
      @click="closed"
    >
      <i class="fa fa-close" style="color: #dcdcdc"></i>
    </button>
    <!-- <iframe
      v-if="this.activeType !== 'plantillas'"
      :src="this.srcUrl"
      height="100%"
      width="100%"
    ></iframe>
    <img
      v-else
      :src="this.srcUrl"
      style="height: 100%; width: 100%; padding: 20px; object-fit: contain"
    /> -->
    <iframe v-if="isPdf" :src="srcUrl" height="100%" width="100%"></iframe>
    <div
      v-else-if="isImage"
      style="
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        height: 100%;
        width: 100%;
      "
    >
      <img
        :src="srcUrl"
        id="imgToDownload"
        style="height: calc(100% - 41px); width: 100%; object-fit: contain"
      />
      <button
        @click="downloadImage(srcUrl, resourceName)"
        class="btn btn-primary"
      >
        Descargar
      </button>
    </div>
    <div
      v-else-if="isAdvertising"
      style="
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        height: 100%;
        width: 100%;
        position: relative;
      "
    >
      <!-- <img
        :src="srcUrl"
        id="imgToDownload"
        style="height: calc(100% - 41px); width: 100%; object-fit: contain"
      />
      <img
        style="width: 90%; padding: 0px 40px 20px 40px"
        :src="this.qrDistribuidor"
      /> -->
      <!-- <div id="adCanvas" style="position: relative; width: 100%; height: auto">
        <img
          :src="srcUrl"
          id="imgToDownload"
          style="height: calc(100% - 41px); width: 100%; object-fit: contain"
        />
        <img
          style="
            width: 90px;
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 0px;
          "
          :src="this.qrDistribuidor"
        />
      </div> -->

      <div style="display: flex; flex-direction: column; align-items: center">
        <!-- Ad Canvas containing the image and the QR code -->
        <div
          id="adCanvas"
          class="adCanvas"
          style="
            position: relative;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <!-- Main Image with object-fit to preserve aspect ratio -->
          <div style="position: relative; width: fit-content; height: 100%">
            <img
              :src="srcUrl"
              id="imgToDownload"
              style="width: 100%; height: auto; object-fit: contain"
            />

            <!-- QR Code Positioning -->
            <div class="qrDynamicContainer">
              <img
                :src="this.qrDistribuidor"
                :style="{ transform: `scale(${qrScale})` }"
                class="qrDynamic"
              />
            </div>
          </div>
        </div>
        <button
          @click="downloadAdvertising(srcUrl, resourceName)"
          class="btn btn-primary"
          style="margin-top: 10px"
        >
          Descargar Publicidad
        </button>
      </div>

      <div id="adCanvas2" class="adCanvas2">
        <!-- Main Image with object-fit to preserve aspect ratio -->
        <div style="position: relative; width: fit-content; height: 100%">
          <img
            :src="srcUrl"
            id="imgToDownload"
            style="width: 100%; height: 100%; object-fit: contain"
          />

          <!-- QR Code Positioning -->
          <div class="qrDynamicContainer2">
            <img
              :src="this.qrDistribuidor"
              style="transform: scale(1.4)"
              class="qrDynamic"
            />
          </div>
        </div>
      </div>
    </div>
    <video
      v-else-if="isVideo"
      controls
      style="height: 100%; width: 100%; object-fit: contain"
    >
      <source :src="srcUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div v-else>Unsupported file type.</div>
  </VueFinalModal>
  <div>
    <div class="foldersContainer">
      <div class="buttonsContainer">
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'documentos' }"
          @click="loadResources('documentos')"
        >
          <i
            class="fa-solid fa-file text-success"
            :class="{ activeBtn: activeType === 'documentos' }"
          ></i>
          <p class="text" style="margin: 0">Documentos</p>
        </button>
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'plantillas' }"
          @click="loadResources('plantillas')"
        >
          <i
            class="fa-solid fa-image text-success"
            :class="{ activeBtn: activeType === 'plantillas' }"
          ></i>
          <p class="text" style="margin: 0">Plantillas</p>
        </button>
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'publicidad' }"
          @click="loadResources('publicidad')"
        >
          <i
            class="fa-solid fa-bullhorn text-success"
            :class="{ activeBtn: activeType === 'publicidad' }"
          ></i>
          <p class="text" style="margin: 0">Publicidad</p>
        </button>
        <button
          class="folder"
          :class="{ activeBtn: activeType === 'videos' }"
          @click="loadResources('videos')"
        >
          <i
            class="fa-solid fa-video text-success"
            :class="{ activeBtn: activeType === 'videos' }"
          ></i>
          <p class="text" style="margin: 0">Videos</p>
        </button>
      </div>
      <hr />
      <div
        v-if="activeType === 'plantillas' && !loading"
        class="archivesContainer"
      >
        <div v-if="pngs.length === 0">No hay Plantillas Disponibles</div>
        <div
          v-else
          v-for="png in pngs"
          :key="png.name"
          @click="clickedResource(png)"
        >
          <!-- <button @click="downloadResource(png)" class="archive"> -->
          <button class="archive">
            <i class="fa-solid fa-image text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ png.name }}</p>
          </button>
        </div>
      </div>
      <div
        v-if="activeType === 'documentos' && !loading"
        class="archivesContainer"
      >
        <div v-if="pdfs.length === 0">No hay Documentos Disponibles</div>
        <div
          v-else
          v-for="pdf in pdfs"
          :key="pdf.name"
          @click="clickedResource(pdf)"
        >
          <!-- <button @click="downloadResource(pdf)" class="archive"> -->
          <button class="archive">
            <i class="fa-solid fa-file text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ pdf.name }}</p>
          </button>
        </div>
      </div>
      <div v-if="activeType === 'videos' && !loading" class="archivesContainer">
        <div v-if="videos.length === 0">No hay Videos Disponibles</div>
        <div
          v-else
          v-for="video in videos"
          :key="video.name"
          @click="clickedResource(video)"
        >
          <!-- <button @click="downloadResource(video)" class="archive"> -->
          <button class="archive">
            <i class="fa-solid fa-video text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ video.name }}</p>
          </button>
        </div>
      </div>
      <div
        v-if="activeType === 'publicidad' && !loading"
        class="archivesContainer"
      >
        <div v-if="publicidad.length === 0">No hay Publicidad Disponible</div>
        <div
          v-else
          v-for="pub in publicidad"
          :key="pub.name"
          @click="clickedResource(pub)"
        >
          <!-- <button @click="downloadResource(video)" class="archive"> -->
          <button class="archive">
            <i class="fa-solid fa-image text-success"></i>
            <p style="font-size: 14px; margin: 0">{{ pub.name }}</p>
          </button>
        </div>
      </div>

      <div v-if="loading" class="archivesContainer">
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
        <div>
          <Skeletor
            :shimmer="true"
            class="skeletorClass"
            style="border-radius: 10px"
          />
        </div>
      </div>
      <!-- <div v-if="activeType === 'documentos'" class="archivesContainer">
        <div v-for="pdf in pdfs" :key="pdf.name">
          <button @click="downloadResource(pdf)" class="archive">
            <i class="fa-solid fa-file text-success"></i>
            <p style="font-size: 14px">{{ pdf.name }}</p>
          </button>
        </div>
      </div>
      <div v-if="activeType === 'videos'" class="archivesContainer">
        <div v-for="video in videos" :key="video.name">
          <button @click="downloadResource(video)" class="archive">
            <i class="fa-solid fa-video text-success"></i>
            <p style="font-size: 14px">{{ video.name }}</p>
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import "firebase/compat/storage";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import html2canvas from "html2canvas";

export default {
  name: "recursos",
  components: {
    VueFinalModal,
  },
  data() {
    const promoCode = JSON.parse(this.$store.state.user).promoCode;

    return {
      activeType: "plantillas",
      pngs: [],
      pdfs: [],
      videos: [],
      publicidad: [],
      loading: true,
      srcUrl: null,
      showModal: false,
      extension: null,
      resourceName: null,
      link: null,
      promoCode: promoCode,
      windowWidth: window.innerWidth, // track the window width
      adLoading: true,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },

  computed: {
    fileExtension() {
      return this.srcUrl ? this.srcUrl.split(".").pop().toLowerCase() : "";
    },
    isPdf() {
      return this.activeType === "documentos" && this.extension === "pdf";
    },
    isImage() {
      return (
        this.activeType === "documentos" ||
        (this.activeType === "plantillas" &&
          ["png", "jpg", "jpeg", "gif"].includes(this.extension))
      );
    },
    isVideo() {
      return this.extension === "mp4";
    },
    isAdvertising() {
      return (
        this.activeType === "publicidad" &&
        ["png", "jpg", "jpeg", "gif"].includes(this.extension)
      );
    },
    // Dynamically calculate the scale based on window width
    qrScale() {
      const minWidth = 0; // Minimum width where scaling goes to 0
      const maxWidth = 480; // Max width where scaling starts at 1.3
      const maxWidth2 = 510; // Max width where scaling starts at 1.3
      const maxScale = 1.3; // Max scale for widths greater than 480px
      const maxScale2 = 1.15; // Max scale for widths greater than 480px
      const minScale = 0.3; // Min scale for very small screens (optional)

      // If the width is greater than the maxWidth (480px), use max scale
      if (this.windowWidth >= maxWidth) {
        return maxScale;
      }

      // Calculate scale factor for widths between 0px and 480px
      const scaleFactor = (this.windowWidth / maxWidth2) * maxScale2;

      // Ensure scale doesn't go below minScale for very small screens
      return Math.max(scaleFactor, minScale);
    },
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth; // update window width when resized
    },

    closed() {
      // this.$eventBus.emit("reiniciarFormVentaDashboard");
      this.showModal = false;
      this.adLoading = true;
      this.srcUrl = null;
    },

    onImageLoad() {
      console.log("Ya");
      this.adLoading = false;
    },

    getQR(link) {
      this.$https
        .post("/codigo/getUserQr", { url: link })
        .then((response) => {
          this.qrDistribuidor = `data:image/png;base64,${response.data.qrCode}`;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downloadAdvertising(srcUrl, resourceName) {
      const divToCapture = document.getElementById("adCanvas2");

      // Update the content dynamically if needed
      const originalDisplay = divToCapture.style.visibility;
      divToCapture.style.display = "visible";

      // Update the image source dynamically
      const imgElement = divToCapture.querySelector("#imgToDownload");
      if (imgElement) {
        imgElement.src = srcUrl;
      }

      html2canvas(divToCapture, {
        width: divToCapture.offsetWidth,
        height: divToCapture.offsetHeight,
        useCORS: true,
      })
        .then((canvas) => {
          const image = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
          const link = document.createElement("a");
          link.download = `${resourceName || "advertising_image.png"}`;
          link.href = image;
          link.click();

          // Restore the original display style
          divToCapture.style.display = originalDisplay;
        })
        .catch((error) => {
          console.error("Error capturing the canvas:", error);
        });
    },

    async loadResources(type) {
      this.loading = true;
      this.activeType = type;
      const storageRef = this.$appFirebase.storage().ref().child(type);
      const listResult = await storageRef.listAll();
      if (type === "plantillas") {
        // this.pngs = listResult.items;
        this.pngs = await Promise.all(
          listResult.items.map(async (item) => {
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      } else if (type === "documentos") {
        // this.pdfs = listResult.items;
        this.pdfs = await Promise.all(
          listResult.items.map(async (item) => {
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      } else if (type === "videos") {
        // this.videos = listResult.items;
        this.videos = await Promise.all(
          listResult.items.map(async (item) => {
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      } else if (type === "publicidad") {
        // publicidad
        this.publicidad = await Promise.all(
          listResult.items.map(async (item) => {
            return {
              name: item.name,
              url: await item.getDownloadURL(),
              ext: item.name.split(".")[1],
            };
          })
        );
      }
      this.loading = false;
    },
    async downloadResource(resource, resourceName) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = resourceName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    async clickedResource(resource) {
      this.extension = resource.ext;
      this.srcUrl = resource.url;
      this.resourceName = resource.name;
      this.showModal = true;
    },
    async downloadImageFromFirebase(path, resourceName) {
      const storage = getStorage();
      getDownloadURL(ref(storage, `images/${resourceName}`))
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            const blob = xhr.response;
          };
          xhr.open("GET", url);
          xhr.send();

          // Or inserted into an <img> element
          const img = document.getElementById("myimg");
          img.setAttribute("src", url);
        })
        .catch((error) => {
          // Handle any errors
        });
    },
    downloadImage(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function (event) {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
      };
      xhr.open("GET", url);
      xhr.send();
    },
  },
  mounted() {
    this.loadResources("documentos");
    this.link = "https://xtrimproec.com" + "/venta/" + this.promoCode;
    this.getQR("https://xtrimproec.com" + "/venta/" + this.promoCode);
    window.addEventListener("resize", this.updateWindowWidth); // listen for resize
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth); // cleanup event listener
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs__search {
  height: 30px;
}

/* .foldersContainer {
  display: flex;
  background-color: white;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;
}

.folder {
  padding: 10px;
  border-radius: 10px;
  min-width: 120px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.active {
  background-color: #7d0c7e; 
  color: white;
  .text {
    color: white;
  }

  i {
    color: white !important;
  }
}

.text {
  color: black;
  font-weight: 600;
}

.folderInfo {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.archive {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 8px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #333333;
  gap: 20px;
}

.archivesContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
}

.skeletorClass {
  width: 200px;
  height: 44px;
}

.archive:hover {
  background-color: #f4f4f4;
} */
</style>
