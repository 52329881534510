<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <p
              style="
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 16px;
              "
            >
              Descripción general
            </p>

            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Xtrim y PlanPro se unen para ofrecerles la más moderna plataforma
              digital de Emprendimiento XTRIMPRO, donde todos pueden referir
              clientes nuevos y ganar dinero por cada persona que contrate el
              Internet más rápido y al mejor precio del Ecuador. Además, pueden
              invitar a más personas a su equipo y ganar de sus ventas en 4
              niveles de profundidad.
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Regístrese gratuitamente y empiecen a ganar con XtrimPro (registro
              gratis por tiempo limitado)
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Además de personas, los usuarios pueden referir empresas, pero la
              comisión se pagará solo si se contrata uno de los 4 planes. Si la
              Empresa necesita un plan personalizado, esto lo venderá
              directamente Xtrim y no habrá comisiones en este caso.
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Los pagos de las comisiones serán realizados por la empresa
              PlanProcad S.A. Estas comisiones serán pagadas únicamente por
              transferencia bancaria a la cuenta que registren en su oficina
              virtual. Es mandatorio que la cuenta pertenezca a la persona
              registrada en la plataforma.
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Si desean pueden registrar en la plataforma a su familia,
              invitándolos con el link de registro para que todos puedan gozar
              de este beneficio.
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <br />
              <b style="margin-bottom: 16px"
                >Comisiones por Ventas Directas y Comisiones por 4 Niveles:</b
              >
              <br />
            </p>
            <div style="margin: 12px; font-size: 12px" class="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Comisión</th>
                    <th v-for="plan in plans" :key="plan.planId">
                      {{ plan.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(comision, index) in comisionTypes" :key="index">
                    <td style="font-weight: 600">{{ comision.title }}</td>
                    <td v-for="plan in plans" :key="plan.planId">
                      {{ formatValue(plan[comision.field], comision.field) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Una vez ingreses a tu referido el Contact Center de Xtrim se
              comunicara con ellos dentro de las siguientes 24 horas.
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <b style="margin-bottom: 16px"> SOBRE EL PAGO DE COMISIONES </b>
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <b style="margin-bottom: 16px"> Pagos por venta directa: </b>
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Las comisiones por ventas directas realizadas por los
              distribuidores serán pagadas semanalmente. Los cortes para el
              cálculo de estas comisiones se realizarán cada jueves a las 12:00
              (hora de Ecuador), considerando únicamente las ventas que estén en
              el estado de "Instalado" hasta esa fecha y hora. Aquellas ventas
              que entren fuera del horario establecido quedarán registradas para
              el cálculo y pago correspondiente a la semana siguiente.
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              El pago de estas comisiones se efectuará dentro de las 48 horas
              siguientes al corte, es decir, entre viernes y sábado inmediato
              siguiente. En caso de que el día de pago coincida con un feriado,
              el pago se realizará el día hábil siguiente al feriado
              correspondiente.
            </p>

            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <b style="margin-bottom: 16px">
                Pagos por Red de Distribución:
              </b>
            </p>

            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Las comisiones por ventas generadas a través de la red de
              distribución se pagan de la siguiente manera:
            </p>

            <div
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <ul>
                <li style="font-size: 14px">
                  Las comisiones se pagarán una vez, confirmada la instalación
                  por parte de Xtrim.
                </li>
                <li style="font-size: 14px">
                  Las comisiones de ventas directas se pagarán cada semana.
                </li>
                <li style="font-size: 14px">
                  Las comisiones por ventas grupales se pagarán de manera
                  mensual.
                </li>
                <li style="font-size: 14px">
                  Esta plataforma tiene certificaciones de seguridad bancaria,
                  por lo que tus datos se encuentran protegidos.
                </li>
              </ul>
            </div>

            <p style="font-size: 16px; font-weight: bold; text-align: center">
              Términos y condiciones
            </p>

            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              En mi calidad de usuario (en adelante “el usuario” o “usuario”) de
              esta plataforma (en adelante plataforma “PLANPRO”) de titularidad
              de PLANPROCAD S.A., reconozco expresamente que al utilizarla debo
              sujetarme en todo momento a lo aquí establecido. Al acceder o
              utilizar esta plataforma web,
              <b
                >acepto expresa, libre y voluntariamente los siguientes Términos
                y Condiciones de Uso.</b
              >
            </p>
            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Reconozco y acepto que la plataforma PLANPRO en la persona de su
              administrador o titular, se reserva el derecho de negarme el
              acceso a la misma en cualquier caso que considere apropiado, en
              particular si yo como usuario:
            </p>

            <div
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <ol>
                <li style="font-size: 14px">Proporciono <b>datos falsos</b></li>
                <li style="font-size: 14px">
                  <b>Incumplo</b> estos Términos y Condiciones de Uso de
                  cualquier forma; y,
                </li>
                <li style="font-size: 14px">
                  Incumplo <b>cualquier normativa legal</b> aplicable respecto
                  del acceso o el uso de la presente plataforma.
                </li>
              </ol>
            </div>

            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Acepta libre, voluntaria y expresamente que soy el único
              responsable del contenido que cargue, publique, envíe por correo
              electrónico, transmita o de cualquier forma ponga a disposición a
              través de esta plataforma.
            </p>

            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Adicionalmente me obligo expresa, libre y voluntariamente a
              <b
                >no utilizar la presente plataforma en forma alguna que sirva
                directa o indirectamente para:</b
              >
            </p>

            <div
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <ul>
                <li style="font-size: 14px">
                  Dañar a otras personas o animales de cualquier forma;
                </li>
                <li style="font-size: 14px">Realizar declaraciones falsas;</li>
                <li style="font-size: 14px">
                  Difundir de cualquier forma contenido que viole un derecho de
                  propiedad intelectual de terceros, incluyendo pero no limitado
                  a marcas, derechos de autor, secretos empresariales, patentes
                  y diseños industriales; y,
                </li>
                <li style="font-size: 14px">
                  Violar cualquier ley o norma jurídica nacional o
                  internacional.
                </li>
              </ul>
            </div>

            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              Reconozco y acepto que la plataforma PLANPRO en la persona de su
              administrador o titular se reserva el derecho de modificar a su
              discreción los presentes términos y condiciones, comunicándome el
              particular, siempre de acuerdo con la ley ecuatoriana.
            </p>

            <p
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <b>Datos Personales:</b> Autorizo expresamente a PLANPROCAD S.A. a
              utilizar mis datos personales entregados o generados por medio de
              mi utilización de la plataforma PLANPRO. Esta autorización incluye
              los siguientes usos:
            </p>

            <div
              style="font-size: 14px; text-align: justify; margin-bottom: 16px"
            >
              <ul>
                <li style="font-size: 14px">Acceso a datos;</li>
                <li style="font-size: 14px">Consulta de buró crediticio;</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

export default {
  name: "Descripcion",
  data() {
    return {
      isMobile: this.$isMobile,
      plans: [
        {
          bono: 3,
          description: "300 Megas",
          name: "X-FÚTBOL",
          planId: "1000604",
          price: 23.5,
          comision: 22,
          comisionFirstLevel: 3,
          comisionFourthLevel: 1.5,
          comisionSecondLevel: 4,
          comisionThirdLevel: 2,
        },
        {
          bono: 5,
          description: "150 Megas",
          name: "X-FÚTBOL STANDARD",
          planId: "1000605",
          price: 21.99,
          comision: 22,
          comisionFirstLevel: 3,
          comisionFourthLevel: 1.5,
          comisionSecondLevel: 4,
          comisionThirdLevel: 2,
        },
        {
          bono: 7,
          description: "400 Megas",
          name: "X-FÚTBOL ESENCIAL",
          planId: "1000607",
          price: 20,
          comision: 22,
          comisionFirstLevel: 3,
          comisionFourthLevel: 1.5,
          comisionSecondLevel: 4,
          comisionThirdLevel: 2,
        },
        {
          bono: 7,
          description: "400 Megas",
          name: "X-FÚTBOL TOTAL",
          planId: "1000607",
          price: 42,
          comision: 26,
          comisionFirstLevel: 5,
          comisionSecondLevel: 6,
          comisionThirdLevel: 3,
          comisionFourthLevel: 2,
        },
        {
          bono: 5,
          description: "400 Megas",
          name: "X-TREAMING TOTAL",
          planId: "1000606",
          price: 35,
          comision: 35,
          comisionFirstLevel: 5,
          comisionFourthLevel: 3,
          comisionSecondLevel: 7.5,
          comisionThirdLevel: 4,
        },
      ],
      comisionTypes: [
        { title: "Venta Directa", field: "comision" },
        { title: "Venta Nivel 1", field: "comisionFirstLevel" },
        { title: "Venta Nivel 2", field: "comisionSecondLevel" },
        { title: "Venta Nivel 3", field: "comisionThirdLevel" },
        { title: "Venta Nivel 4", field: "comisionFourthLevel" },
      ],
    };
  },
  methods: {
    formatValue(value) {
      return `$${value.toFixed(2)}`;
    },
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("inactiveShowMenu");
    }
  },
  mounted() {},
};
</script>

<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* Ensure table maintains minimum width */
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center; /* Align text to the center */
}

th {
  background-color: #f2f2f2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  th,
  td {
    padding: 4px;
    font-size: 12px;
  }

  th:first-child,
  td:first-child {
    display: none; /* Hide the first column on smaller screens */
  }
}
</style>
